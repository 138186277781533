<template>
    <Float
        :shift="16"
        :offset="8"
        placement="bottom-start"
        enter="transition duration-200 ease-out"
        enter-from="scale-95 opacity-0"
        enter-to="scale-100 opacity-100"
        leave="transition duration-150 ease-in"
        leave-from="scale-100 opacity-100"
        leave-to="scale-95 opacity-0"
    >
        <slot />
    </Float>
</template>
